// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
import React from 'react'
import Helmet from 'react-helmet'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals
import organisation from '../../seo/organisation.json'
import website from '../../seo/website.json'

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/** OrganisationSchema */
const OrganisationSchema = () => {
  const schemaOrgJSONLD = {
    '@context': 'http://schema.org',
    '@type': 'Organization',
    '@id': `${website.nakedUrlWithIntl}/org`,
    legalName: organisation.legalName,
    url: organisation.url,
    logo: organisation.logo,
    foundingDate: organisation.foundingDate,
    founders: organisation.founders.map((founder) => ({
      '@type': 'Person',
      name: founder,
    })),
    address: {
      name: organisation.address.locationName,
      streetAddress: organisation.address.streetAddress,
      addressLocality: organisation.address.addressLocality,
      addressRegion: organisation.address.addressRegion,
      addressCountry: organisation.address.addressCountry,
      postalCode: organisation.address.postalCode,
    },
    telephone: organisation.telephone,
    email: organisation.email,
    contactPoint: {
      '@type': 'ContactPoint',
      contactType: 'customer service',
      telephone: organisation.telephone,
      email: organisation.email,
    },
    sameAs: organisation.sameAs,
  }

  return (
    <Helmet>
      <script id="organisation-schema" type="application/ld+json">
        {JSON.stringify(schemaOrgJSONLD)}
      </script>
    </Helmet>
  )
}

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Export
// ----------------------------------------------------------------------------
export default OrganisationSchema
