// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
import React from 'react'
import Helmet from 'react-helmet'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals
import indexImage from '../../images/banners/launch.jpg'
import website from '../../seo/website.json'
import about from '../../seo/about.json'
import organisation from '../../seo/organisation.json'
import geoLocation from '../../seo/geo-location.json'

import withUrl from '../../methods/withUrl'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
const dateModified = new Date().toISOString()
const copyrightYearEnds = new Date().getFullYear()
const indexImageWithUrl = withUrl(indexImage)

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/** WebsiteSchema */
const WebsiteSchema = ({ data: { cover } = {} }) => {
  const imageUrl =
    typeof cover === 'undefined' ? indexImageWithUrl : withUrl(cover)

  const schemaOrgJSONLD = {
    '@context': 'http://schema.org',
    '@type': 'WebSite',
    '@id': website.nakedUrl,
    name: website.name,
    url: website.nakedUrl,
    image: imageUrl,
    abstract: about.abstract,
    description: about.description,
    text: about.text,
    keywords: about.keywords,
    copyrightYear: `${website.copyrightYearBegins}–${copyrightYearEnds}`,
    copyrightNotice: website.copyrightNotice,
    dateCreated: website.created,
    dateModified,
    copyrightHolder: {
      '@context': 'http://schema.org',
      '@type': 'Organization',
      name: organisation.name,
      legalName: organisation.legalName,
      url: organisation.url,
      logo: organisation.logo,
      foundingDate: organisation.foundingDate,
      founders: organisation.founders.map((founder) => ({
        '@type': 'Person',
        name: founder,
      })),
      address: {
        name: organisation.address.locationName,
        streetAddress: organisation.address.streetAddress,
        addressLocality: organisation.address.addressLocality,
        addressRegion: organisation.address.addressRegion,
        addressCountry: organisation.address.addressCountry,
        postalCode: organisation.address.postalCode,
      },
      telephone: organisation.telephone,
      email: organisation.email,
      contactPoint: {
        '@type': 'ContactPoint',
        contactType: 'customer service',
        telephone: organisation.telephone,
        email: organisation.email,
      },
      sameAs: organisation.sameAs,
    },
    contentLocation: {
      '@context': 'https://schema.org',
      '@type': 'Place',
      name: geoLocation.locationName,
      url: geoLocation.locationUrl,
      geo: {
        '@type': 'GeoCoordinates',
        latitude: geoLocation.latitude,
        longitude: geoLocation.longitude,
      },
      address: {
        '@type': 'PostalAddress',
        name: geoLocation.locationName,
        streetAddress: geoLocation.streetAddress,
        addressLocality: geoLocation.addressLocality,
        addressRegion: geoLocation.addressRegion,
        addressCountry: geoLocation.addressCountry,
        postalCode: geoLocation.postalCode,
      },
    },
    locationCreated: {
      '@context': 'https://schema.org',
      '@type': 'Place',
      name: geoLocation.locationName,
      url: geoLocation.locationUrl,
      geo: {
        '@type': 'GeoCoordinates',
        latitude: geoLocation.latitude,
        longitude: geoLocation.longitude,
      },
      address: {
        '@type': 'PostalAddress',
        name: geoLocation.locationName,
        streetAddress: geoLocation.streetAddress,
        addressLocality: geoLocation.addressLocality,
        addressRegion: geoLocation.addressRegion,
        addressCountry: geoLocation.addressCountry,
        postalCode: geoLocation.postalCode,
      },
    },
    author: {
      '@context': 'http://schema.org',
      '@type': 'Organization',
      name: organisation.name,
      legalName: organisation.legalName,
      url: organisation.url,
      logo: organisation.logo,
      foundingDate: organisation.foundingDate,
      founders: organisation.founders.map((founder) => ({
        '@type': 'Person',
        name: founder,
      })),
      address: {
        name: organisation.address.locationName,
        streetAddress: organisation.address.streetAddress,
        addressLocality: organisation.address.addressLocality,
        addressRegion: organisation.address.addressRegion,
        addressCountry: organisation.address.addressCountry,
        postalCode: organisation.address.postalCode,
      },
      telephone: organisation.telephone,
      email: organisation.email,
      contactPoint: {
        '@type': 'ContactPoint',
        contactType: 'customer service',
        telephone: organisation.telephone,
        email: organisation.email,
      },
      sameAs: organisation.sameAs,
    },
    publisher: {
      '@context': 'http://schema.org',
      '@type': 'Organization',
      name: organisation.name,
      legalName: organisation.legalName,
      url: organisation.url,
      logo: organisation.logo,
      foundingDate: organisation.foundingDate,
      founders: organisation.founders.map((founder) => ({
        '@type': 'Person',
        name: founder,
      })),
      address: {
        name: organisation.address.locationName,
        streetAddress: organisation.address.streetAddress,
        addressLocality: organisation.address.addressLocality,
        addressRegion: organisation.address.addressRegion,
        addressCountry: organisation.address.addressCountry,
        postalCode: organisation.address.postalCode,
      },
      telephone: organisation.telephone,
      email: organisation.email,
      contactPoint: {
        '@type': 'ContactPoint',
        contactType: 'customer service',
        telephone: organisation.telephone,
        email: organisation.email,
      },
      sameAs: organisation.sameAs,
    },
  }

  return (
    <Helmet>
      <script id="website-schema" type="application/ld+json">
        {JSON.stringify(schemaOrgJSONLD)}
      </script>
    </Helmet>
  )
}

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Export
// ----------------------------------------------------------------------------
export default WebsiteSchema
