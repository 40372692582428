// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React from 'react'
// import compose from 'recompose/compose'
// import { connect } from 'react-redux'
import filter from 'lodash/filter'
import endsWith from 'lodash/endsWith'
import debounce from 'lodash/debounce'
import map from 'lodash/map'
import groupBy from 'lodash/groupBy'
import head from 'lodash/head'
import takeRight from 'lodash/takeRight'
import matches from 'lodash/matches'
import startCase from 'lodash/startCase'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components
import { Spring, animated } from 'react-spring'
import { StaticQuery, graphql } from 'gatsby'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals
import about from '../../seo/about.json'

import Link from '../link'
import '../link/style.less'

import OneMany from '../animations/one-many'
import '../animations/one-many/style.less'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
const { Fragment } = React

// ----------------------------------------------------------------------------
// ---------------------------------------------------------------------- Query
// ----------------------------------------------------------------------------
export const query = graphql`
  query {
    allResources(sort: { order: ASC, fields: position }) {
      edges {
        node {
          title {
            lang
            content
          }
          position
          routeSlug
        }
      }
    }
  }
`

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/** [description] */
const TableOfContents = React.memo(({ nodes, nodePositions, update }) => (
  <nav className="flow table-of-contents">
    <li>
      <Link to="/">
        <span style={{ width: '100%', padding: 'unset' }}>Cover Page</span>
      </Link>
    </li>
    {map(nodePositions, (nodePosition) => {
      const hasChildren = nodePosition.length > 1
      const first = head(nodePosition, 1)
      const children =
        hasChildren === true
          ? takeRight(nodePosition, nodePosition.length - 1)
          : []

      return (
        <Fragment>
          {hasChildren === false && (
            <li>
              <Link
                to={filter(nodes, matches({ position: first }))[0].routeSlug}
                onClick={() => update(false, 'root')}
              >
                <span>
                  {filter(nodes, matches({ position: first }))[0].intlTitle}
                </span>
                <span>{nodePosition}</span>
              </Link>
            </li>
          )}
          {hasChildren === true && (
            <li>
              <Link
                style={{ display: 'block', marginBottom: '0.38rem' }}
                to={filter(nodes, matches({ position: first }))[0].routeSlug}
                onClick={() => update(false, 'root')}
                className="block-heading"
              >
                <span>
                  {filter(nodes, matches({ position: first }))[0].intlTitle}
                </span>
                <span>{first}</span>
              </Link>
              <ul>
                {map(children, (child) => (
                  <li>
                    <Link
                      to={`${
                        filter(nodes, matches({ position: first }))[0].routeSlug
                      }#${
                        filter(nodes, matches({ position: child }))[0].routeSlug
                      }`}
                      onClick={() => update(false, 'root')}
                    >
                      <span>
                        {
                          filter(nodes, matches({ position: child }))[0]
                            .intlTitle
                        }
                      </span>
                      <span>{child}</span>
                    </Link>
                  </li>
                ))}
              </ul>
            </li>
          )}
        </Fragment>
      )
    })}
  </nav>
))

const Slider = React.memo(
  ({ isActive, whatsActive, update, wrapperRef, nodes, nodePositions }) => (
    <Spring
      native
      reset
      reverse={!isActive}
      from={{ height: '0vh', paddingBottom: 0 }}
      to={{
        height: whatsActive === 'root' ? '0vh' : '100vh',
        paddingBottom: 100,
      }}
    >
      {(props) => (
        <animated.aside
          ref={wrapperRef}
          className={`slider ${isActive === true ? 'active' : 'passive'}`}
          style={{
            height: props.height,
            maxHeight: props.height,
            paddingBottom: props.paddingBottom,
            paddingTop: isActive === true ? 'calc(1rem + 54px)' : '0px',
          }}
        >
          <div className="carrier">
            <TableOfContents
              update={update}
              nodes={nodes}
              nodePositions={nodePositions}
            />
            <ul className="etc">
              <li>
                <Link to="/about">About</Link>
              </li>
              <li>
                <Link to="/buy-or-contribute">Buy or Contribute</Link>
              </li>
              {/*<li>
                <Link to="/impressum">Impressum</Link>
              </li>*/}
              <li>
                <Link to="/copyright">Copyright</Link>
              </li>
              {/*<li>
                <Link to="/options">Options</Link>
              </li>*/}
            </ul>
          </div>
        </animated.aside>
      )}
    </Spring>
  )
)

/** Header */
class Header extends React.Component {
  /** [constructor description] */
  constructor() {
    super()

    this.state = {
      isActive: false,
      whatsActive: 'root',
      prevScrollPos: 0,
      visible: true,
    }

    this.linkRef = React.createRef()
    this.wrapperRef = React.createRef()

    this.update = this.update.bind(this)
    this.escFunction = this.escFunction.bind(this)
    this.setPrevScrollPos = this.setPrevScrollPos.bind(this)
    this.setVisible = this.setVisible.bind(this)
    this.handleClickOutside = this.handleClickOutside.bind(this)
    this.handleScroll = debounce(this.handleScroll.bind(this), 100)
  }

  /** [componentDidMount description] */
  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside)
    document.addEventListener('keydown', this.escFunction, false)
    document.addEventListener('scroll', this.handleScroll)
  }

  /** [shouldComponentUpdate description] */
  shouldComponentUpdate(nextProps, nextState) {
    const { visible: thisVisible, isActive: thisIsActive } = this.state
    const { visible: thatVisible, isActive: thatIsActive } = nextState

    let shouldUpdate = false

    if (thisVisible !== thatVisible) {
      shouldUpdate = true
    }

    if (thisIsActive !== thatIsActive) {
      shouldUpdate = true
    }

    return shouldUpdate
  }

  /** [componentWillUnmount description] */
  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside)
    document.removeEventListener('keydown', this.escFunction, false)
    document.removeEventListener('scroll', this.handleScroll)
  }

  /** [handleClickOutside description] */
  handleScroll() {
    const currentScrollPos = window.pageYOffset
    const { prevScrollPos } = this.state

    this.setPrevScrollPos(currentScrollPos)
    this.setVisible(prevScrollPos > currentScrollPos)
  }

  /** [handleClickOutside description] */
  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
      const { linkRef } = this.props

      if (linkRef && !linkRef.current.contains(event.target)) {
        const { isActive, update } = this.props
        if (isActive === true) {
          update(false, 'root')
        }
      }
    }
  }

  /** [update description] */
  setVisible(visible) {
    this.setState({ visible })
  }

  /** [update description] */
  setPrevScrollPos(prevScrollPos) {
    this.setState({ prevScrollPos })
  }

  /** [escFunction description] */
  escFunction(event) {
    if (event.keyCode === 27) {
      const { isActive, update } = this.props
      if (isActive === true) {
        update(false, 'root')
      }
    }
  }

  /** [update description] */
  update(isActive, whatsActive) {
    this.setState({ isActive, whatsActive })
  }

  /** [render description] */
  render() {
    const { uri, originalPath, lang } = this.props
    const { isActive, whatsActive, visible } = this.state
    const top = visible ? '0' : 'calc(-1 * calc(1rem + 54px))'

    return (
      <StaticQuery
        query={query}
        render={(data) => {
          const nodes = map(data.allResources.edges, 'node').slice(0, -1)
          const intlNodes = map(nodes, (node) => ({
            ...node,
            intlTitle: filter(node.title, ['lang', lang])[0].content,
          }))
          const nodePositions = groupBy(map(intlNodes, 'position'), Math.floor)

          const match = filter(intlNodes, ({ routeSlug }) =>
            endsWith(uri, routeSlug)
          )

          let title = 'Title'
          let ref = '/'

          if (match.length > 0) {
            title = match[0].intlTitle
            ref = match[0].routeSlug
          } else {
            ref = uri

            if (originalPath === '/') {
              title = 'Cover page'
            } else {
              title = startCase(originalPath)
            }
          }

          return (
            <Fragment>
              <div className="buttons" style={{ top }}>
                <div className="carrier">
                  <div className="chapter">
                    <div>
                      <Link to="/" aria-label="Title page">
                        <small>{about.altTitle} >></small>
                      </Link>
                      <Link to={ref} aria-label="Title page">
                        <big>{title}</big>
                      </Link>
                    </div>
                  </div>
                  <div ref={this.linkRef} className="contents">
                    <OneMany
                      onClick={() => {
                        if (isActive === false) {
                          this.update(true, 'table-of-contents')
                        }
                        if (isActive === true) {
                          this.update(false, 'root')
                        }
                      }}
                      isActive={isActive}
                    />
                  </div>
                </div>
              </div>
              <Slider
                isActive={isActive}
                whatsActive={whatsActive}
                update={this.update}
                linkRef={this.linkRef}
                wrapperRef={this.wrapperRef}
                nodes={intlNodes}
                nodePositions={nodePositions}
              />
            </Fragment>
          )
        }}
      />
    )
  }
}

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Export
// ----------------------------------------------------------------------------
export default Header
